<!-- 资格预审企业版 -->
<template>
  <div class="mains">
    <div class="templates">
      <div :class="['status',daytimes?'':'daytimes',allData.is_termination==1?'stop':'',]">
        <div class="status_one">
          <p v-if="allData.is_termination==0">{{daytimes?'发布中':'已失效'}}</p>
          <p v-if="allData.is_termination == 1">招标终止</p>
        </div>
      </div>
      <p class="bidding-title">
        <span style="font-size: 18px; " :class="showColor?'colors':''">{{showList.bidding_name}}</span
        ><span style="font-size: 18px">资格预审公告</span>
      </p>
      <p style="text-align: center">
        <span style="font-size: 18px"
          >（招标编号：{{showList.serial_number}}）</span>
      </p>
      <p style="text-align: left">
        <strong><span style="font-size: 16px">一、招标条件</span></strong>
      </p>
      <p style="text-align: left" class="fonts">
        本招标项目为{{showList.bidding_name}}(招标编号：{{showList.serial_number}})，<template v-if="template_type">
							已由<span>{{showList.unit}}</span>以<span>{{showList.titanic}}</span>批准，
					</template>招标人为{{showList.username}}，招标项目资金已落实。本项目已具备招标条件，现对该项目进行{{bidding_way_str}}。
      </p>
      <p style="text-align: left">
        <strong
          ><span style="font-size: 16px">二、项目概况与招标范围</span></strong
        >
      </p>
      <p class="fonts">
        1、项目概况
      </p>
      <div style="padding-left:2em;" >
         <div class="fonts-1em project-info"  v-html="showList.project_describe"></div>
      </div>
      <p  v-if="allData.block_set_type==0">
        <span style="font-size: 14px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、招标类型：</span>
        {{showList.notice_block_set[0].tender_type_name}}
      </p>
      <p style="text-align: left" v-if="allData.block_set_type == 1">
        <span style="font-size: 14px"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、标段设置<br
        /></span>
      </p>
      <p style="text-align: left" v-if="allData.block_set_type == 1">
        <span v-if='showList.notice_block_set == "${标段设置}"' style="font-size: 14px"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
            style="font-size: 16px; "
            :class="showColor?'colors':''">${标段设置}</span
          ><br
        /></span>
        <div  v-if="allData.block_set_type == 1">
            <el-table
						:data="notice_block_set"
						style="width: 100%"
						:header-cell-style="headerStyle"
						:row-style="rowStyle"
						border
					>
                    <el-table-column
							label="序号"
							width="69"
							align="center"
						>
							<template slot-scope="scope">
								<span>{{scope.$index+1}}</span>
							</template>
						</el-table-column>
            <el-table-column
							label="标段号"
							width="206"
							align="center" >
							<template slot-scope="scope">
								<span>第{{scope.$index+1}}标段</span>
							</template>
						</el-table-column>
            <el-table-column
							width="206"
							align="center">
							<template slot="header">
								<span>招标类型</span>
							</template>
							<template slot-scope="scope">
								<span>
									{{scope.row.tender_type_name}}
								</span>
							</template>
						</el-table-column>

            <el-table-column
							width="206"
							align="center"
						>
							<template slot="header">
								<span>标段名称</span>
							</template>
							<template slot-scope="scope">
								<span>
									{{scope.row.block_name ? scope.row.block_name : '/'}}
								</span>
							</template>
						</el-table-column>
            <el-table-column
							label="备注"
							align="center"
						>
							<template slot-scope="scope">
								<span>
									{{scope.row.remark ? scope.row.remark : '/'}}
								</span>
							</template>
						</el-table-column>
					</el-table>
        </div>
      </p>
      <p class="fonts">
        关于招标范围、交货期、规格型号及主要技术要求详见招标文件。
      </p>
      <p style="font-size: 16px;"><strong>三、申请人资格要求</strong></p>
      <div style="padding-left:2em;">
        <div v-html="showList.preliminary_data_other"></div>
      </div>
      <p><span style="font-size: 16px;"><strong><span style="font-size: 16px;">四、资格预审办法</span></strong></span></p>
      <p class="fonts">本次资格预审评审办法：{{showList.preliminary_review_way}}。</p>
      <p style="font-size: 16px;"><strong>五、资格预审文件获取</strong></p>
      <p class="fonts">
				1、凡有意参加资格预审的申请人，请于{{showList.release_start_time}} - {{showList.release_end_time}}
				（北京时间），登陆 {{allData.platform_name}}（ <a :href="location.href" target="_blank" class="empha">{{location.href}}</a>）查看公告报名或登录<span>{{allData.platform_name}}</span>企业工作台应邀报名。</p>
      <p style="font-size: 16px;"><strong>六、资格预审申请文件的递交</strong></p>
			<p class="fonts">资格预审文件的递交时间为{{showList.inquiry_start_time}} - {{showList.inquiry_end_time}}（北京时间），申请人应在递交截止时间前
			通过{{allData.platform_name}}-企业工作台递交资格预审文件并通过资格预审，本项目不接受线下递交资格预审申请文件。</p>
      <p style="font-size: 16px;"><strong>七、发布公告媒介</strong></p>
			<p class="fonts"><span >本次资格预审公告在{{allData.platform_name}}平台(<a :href="allData.platform_domain" class="empha" target="_blank">{{allData.platform_domain}}</a>)和<span 
			>{{showList.institutions}}</span>企业工作台中(<a :href="allData.company_domain" class="empha" target="_blank">{{allData.company_domain}}</a>)以公告形式发布。</span><br/></p>
      <p style="font-size: 16px;"><strong>八、联系方式</strong></p>
			<div style="display:flex;flex-wrap: wrap;padding-left:2em;">
				<div style="width:50%;margin-top:16px;" v-for="(row,index) in notice_contact" :key="index">
					<p>
						<span>招标机构：</span><span>{{row.company_name}}</span>
					</p>
					<p>
						<span>地址：</span><span>{{row.address||'--'}}</span>
					</p>
					<p>
						<span>联系人：</span><span>{{row.contact_name}}</span>
					</p>
					<p>
						<span >联系方式：</span><span>{{row.contact_way}}</span>
					</p>
				</div>
			</div>
			
    </div>
    <div class="rights">
        <p @click="lookMore">{{showList.username}}</p>
        <p>发布时间：{{showList.release_start_time}}</p>
        <div @click="addCateBtn" :class="daytimes&&is_valid?'':'changColor'">
            加入商机
        </div>
        <!-- <el-divider></el-divider> -->
        <!-- <div class="timesAll">
           <img style="width:20px" src="@/assets/img/collection/shareOperation.png" alt=""> <span>时间汇总</span>
        </div> -->
        <div style='margin-top:28px'>
            <el-timeline :reverse="false">
     <el-timeline-item v-for="(item,index) in activities" :key='index' color="#0088fe" :timestamp="item.titles" placement="top">
        <p class="times">{{item.start}}</p>
        <p class="times">{{item.end}}</p>
    </el-timeline-item>
  </el-timeline>
        </div>
    </div>
    <classification v-show='cate_visible'  @fromList="gotoOtherBussiness"  @close="cate_visible = !1"> </classification>
  </div>
  </div>
</template>

<script>
import {post, get} from "@/server/ajax.js";
import classification from '@/components/classification/classification.vue'
export default {
  props: ["messages"],
	components:{
		classification
	},
  data() {
    return {
      daytimes: true, //false 代表已失效 ，ture代表发布中
      is_valid:true,
      rowStyle: {
        //表格行样式
        color: "#333",
        fontSize: "12px",
        height: "48px",
      },
      headerStyle: {
        //表格头部样式
        background: "#F5F5F5",
        color: "#333",
        fontSize: "14px",
        height: "48px",
      },
      showColor: true,
			template_type:false,
      allData: {},
      showList: {
        bidding_name: "&{招标名称}",
        serial_number: "&{招标编号}",
        project_describe: "&{项目描述}",
        username: "&{招标人}",
        notice_block_set: "&{标段设置}",
        preliminary_data_other: "&{投标人资格要求}",
        release_start_time: "&{公告发布时间}",
        release_end_time: "&{公告发布时间}",
        institutions: "${招标机构}",
        bidding_end_time: "${开标时间}",
        project_address_details: "${招标人地址}",
        bidding_period_end_time: "${投标截止时间}",
        tender_buy_start_time: "${标书购买时间}",
        tender_buy_end_time: "${标书购买截止}",
        inquiry_start_time: "${资格预审时间}",
        inquiry_end_time: "${资格预审结束时间}",
        domain: "${域名}",
        preliminary_review_way: "${评审办法}",
        platform_name:'${平台名称}'
      },
			location:window.location,
      notice_contact: [
        {
          contact_name: "",
        },
        {
          contact_name: "",
        },
      ],
      notice_block_set: [],
      activities: [],
      cate_visible:!1,
      staff_info:{},
      bind_info:{},
      bidding_way_str:'',
    };
  },
  created() {
    // get("/api/bidding/common/template/notice_template/134").then((res) => {

    this.allData = this.messages.data.notice;
    // this.allData.domain = this.messages.data.domain;
		this.allData.platform_domain = 'http://'+this.messages.data.platform_domain;
		this.allData.platform_name = this.messages.data.platform_name;
		this.allData.company_domain = 'http://'+this.messages.data.company_domain;
		if( this.allData.notice_template_content && this.allData.notice_template_content.length>0){
			this.template_type = this.allData.notice_template_content[0].template_type == 1;
		}
		
    if(this.allData.is_termination == 1){
      this.is_valid = false;
    }
    this.bidding_way_str = this.mapBiddingWay(this.allData.bidding_way_plus_invite);
    this.getSaveData();
    for (let keys in this.showList) {
      this.showList[keys] = this.allData[keys];
    }
    if (this.showList.preliminary_review_way == 0) {
      this.showList.preliminary_review_way = "合格制";
    } else {
      this.showList.preliminary_review_way =
        "数量制(大于" + this.allData.preliminary_review_number + ")";
    }
    this.showList.platform_name = this.messages.data.platform_name;
    this.notice_block_set = this.allData["notice_block_set"];
    this.notice_contact = this.allData["notice_contact"]; //联系人方式
    let activities = [];
    activities[0] = {
      titles: "公告时间",
      start: "开始：" + this.allData.release_start_time,
      end: "结束：" + this.allData.release_end_time,
    };
    activities[1] = {
      titles: "报名时间",
      start: "开始：" + this.allData.sign_up_start_time,
      end: "结束：" + this.allData.sign_up_end_time,
    };
    activities[2] = {
      titles: "资格预审",
      start: "开始：" + this.allData.inquiry_start_time,
      end: "结束：" + this.allData.inquiry_end_time,
    };
    activities[3] = {
      titles: "标书购买",
      start: "开始：" + this.allData.tender_buy_start_time,
      end: "结束：" + this.allData.tender_buy_end_time,
    };
    activities[4] = {
      titles: "投标时间",
      start: "开始：" + this.allData.bidding_period_start_time,
      end: "结束：" + this.allData.bidding_period_end_time,
    };
    activities[5] = {
      titles: "开标时间",
      start: "开始：" + this.allData.bidding_end_time,
      end: "",
    };
    // 判断是否失效
    if (
      new Date().getTime() > new Date(this.allData.sign_up_end_time).getTime()
    ) {
      this.daytimes = false;
    }else{
      this.daytimes = true;
    }
    if(this.allData.is_bind){
      this.is_valid = false;
    }
    this.activities = activities;
    this.showColor = false; // 祛除颜色
    // });
  },
  methods: {
    getSaveData(){
      get("/base/user/login_info").then((res) => {
          if (res.code == 200) {
            this.staff_info = res.data.staff;
            this.bind_info = res.data.company;
          }
        }).catch((err) => {
          console.log('获取投标人信息失败');
        });
    },
    lookMore() {
      let company_id = this.allData.company_id
      this.$router.push({
        path: "/messageList",
        query: {
          company_id: company_id,
          type:this.$route.query.type,
        },
      });
    },
    mapBiddingWay(num){
      let str = '';
      switch(num){
        case(1):str = "公开招标";break;
        case(2):str = "入围招标";break;
        case(3):str = "邀请招标";break;
        case(4):str = "公开招标和邀请招标";break;
        case(5):str = "入围招标和邀请招标";break;
        default: str = "公开招标";
      }
      return str
    },
    addCateBtn(){
      if(!this.bind_info.name){
        return this.$message.error("请先登录后在加入商机")
      }
      if(!this.is_valid){
        return this.$message.error("项目已经加入商机")
      }
      if(this.daytimes && this.is_valid){
        this.cate_visible = !0;
      }else{
        this.$message.error("招标项目已失效")
      }
    },
    gotoOtherBussiness(data) {
      this.cate_visible = !1;
      if (this.daytimes) {
        let params = {
          serial_number: this.bind_info.serial_number, // 投标人编号
          bind_name: this.bind_info.name, // 投标人名称
          contact_account: this.staff_info.name, // 联系人账号
          contact_name: this.staff_info.name, // 联系人姓名
          contact_phone: this.staff_info.mobile||this.staff_info.tel, // 联系人电话
          project_classify_id:data.data_id,
          project_classify_name:data.classify_name,
        };
        post(
          "/api/bidding/front_desk/tender/tender/add_bind/" + this.allData.id,
          params
        ).then((res) => {
          if(res.code==200){
            this.$message.success("添加成功")
            this.is_valid = false;
						this.cate_visible = false;
          }
        }).catch(err=>{
          console.log('/tender/add_bind',err);
        })
      }
    },
  },
};
</script>
<style lang="scss">
  .templates .project-info span{
      word-wrap: break-word;
      word-break:break-all;
      white-space:unset!important;
  }
</style>
<style lang="scss" scoped>
.empha{
	color:#4387F8;
	cursor: pointer;
}
.templates {
  padding: 40px;
  line-height: 32px;
  box-sizing: border-box;
  position: relative;
  .bidding-title{
    margin-left: 64px;
    margin-right:64px;
    text-align: center;
  }
  .status {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 3px solid #4387F8;
    .status_one {
      top: 4px;
      left: 4px;
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 3px solid #4387F8;
      p {
        width: 58px;
        border-radius: 5px;
        top: 10px;
        left: -3px;
        text-align: center;
        position: absolute;
        background-color: #fff;
        text-align: center;
        transform: rotate(-25deg);
        font-weight: 800;
        color: #4387F8;
      }
    }
  }
  .daytimes {
    border-color: #aaa;
    .status_one {
      border-color: #aaa;
      p {
        color: #aaa;
      }
    }
  }
  .stop{
    border-color: #F26161!important;
    .status_one {
      border-color: #F26161!important;
      p {
        color: #F26161!important;
      }
    }
  }
}
.fonts{
	text-indent: 2em;
}
.fonts-1em{
	text-indent:1em;
}
.fonts-3em{
	text-indent:3em;
}
.mains {
  padding: 20px 0;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  & > div:nth-child(1) {
    width: 880px;
    background-color: #fff;
  }
  & > div:nth-child(2) {
    width: 300px;
    margin-left: 20px;
    background-color: #fff;
    height: auto;
    box-sizing: border-box;
  }
}
.colors {
  color: rgb(0, 176, 240);
}
.rights {
  padding: 20px;
  box-sizing: border-box;
  & > p:nth-child(1) {
    color: #333;
    font-size: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    margin: 8px 0 17px 0;
    // line-height: 16px;
    cursor: pointer;
    &:hover{
      color: #0088fe;
      font-weight: bolder;
    }
  }
  & > p:nth-child(2) {
    color: #999;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 17px;
  }
  & > div:nth-child(3) {
    width: 100%;
    height: 40px;
    color: #fff;
    text-align: center;
    background-color: #f74848;
    border-radius: 3px;
    font-size: 16px;
    line-height: 40px;
    cursor: pointer;
  }
  .changColor {
    background-color: #f74848;
    opacity: 0.2;
  }
}
.timesAll {
  margin-bottom: 18px;
  display: flex;
  img{
    margin-right: 10px;
  }
}
.times {
  font-size: 12px;
  line-height: 28px;
  color: #999;
}
/deep/ .el-timeline-item__timestamp,
.is-top {
  font-weight: 800;
  font-size: 14px;
 color: #0088fe;
}
</style>
